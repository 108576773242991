import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({
  matrix: state.play.actual?.matrix,
  size: state.play.actual?.size,
  langKey: props.pageContext.langKey,
})

const mapDispatch = (dispatch: any) => ({
  onMount: async () => {
    await dispatch(actions.play.selectGame({ id: "5x5.1" }))
    dispatch(actions.demo.next())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
