import React from "react"
import Index from "../components/pages/Index"
import { Seo } from "../components/Seo/Seo"

export default props => (
  <>
    <Seo
      title="Play Futoshiki, Puzzle Game like Sudoku"
      description=""
      lang={"en"}
      defaultLangUrl="/"
      langUrls={[
        { url: "/", lang: "en" },
        { url: "/fr/", lang: "fr" },
        { url: "/de/", lang: "de" },
      ]}
    />
    <Index {...props} />
  </>
)
