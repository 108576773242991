import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { Link } from "gatsby"
import { MatrixEntity } from "../../entities/GameEntity"
import { Grid } from "../Grid/Grid"
import { connector, ContainerProps } from "./containers/Index.containers"
import Logo from "../../assets/logo/logo.svg"
import { FormattedMessage } from "react-intl"
import { getMode } from "../../utils/urls"
import { BackgroundShapes } from "../../modules/authentication/components/BackgroundShapes"

type Props = {
  langKey: string
  matrix: MatrixEntity
  onMount: Function
}

export const IndexWrapper: React.FC<Props> = ({ langKey, matrix, onMount }) => {
  useEffect(() => {
    onMount()
  }, [])

  return (
    <>
      <div className="relative overflow-hidden bg-gray-50">
        <BackgroundShapes step={0} absolute />
        <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
          <nav className="relative flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <div>
                  <span className="sr-only">Futoshiki</span>
                  <img className="w-auto h-12" src={Logo} alt="futoshiki" />
                </div>
              </div>
            </div>
            <div className="block text-right">
              <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                <Link
                  to={getMode()}
                  className="inline-flex items-center px-4 py-2 text-base font-medium text-blue-600 bg-white border border-transparent rounded-md hover:bg-gray-50"
                >
                  <FormattedMessage id="index.header.play" />
                </Link>
              </span>
            </div>
          </nav>

          <main className="px-4 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6 lg:mt-32">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <div>
                  <span className="block text-sm font-semibold tracking-wide text-gray-500 uppercase sm:text-base lg:text-sm xl:text-base">
                    <FormattedMessage id="index.hero.subtitle" />
                  </span>
                  <h1 className="block mt-1 text-4xl font-extrabold tracking-tight sm:text-5xl xl:text-6xl">
                    <span className="block text-gray-900">
                      <FormattedMessage id="index.hero.title.first" />
                    </span>
                    <span className="block text-blue-600">
                      <FormattedMessage id="index.hero.title.last" />
                    </span>
                  </h1>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  <FormattedMessage id="index.hero.description" />
                </p>
              </div>
              <div className="relative mt-12 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative w-full mx-auto lg:max-w-md">
                  <div className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center group">
                    <div className="absolute top-0 bottom-0 left-0 right-0 duration-300 ease-in-out transform scale-105 bg-black opacity-0 cursor-pointer group-hover:opacity-5"></div>
                    <Link
                      to="/app/"
                      className="relative z-10 inline-flex items-center justify-center px-10 py-4 text-base font-medium text-blue-600 transition-all duration-300 ease-in-out transform bg-white border border-transparent rounded-md shadow-md opacity-0 hover:-translate-y-1 group-hover:opacity-100"
                    >
                      <FormattedMessage id="index.try.play" />
                    </Link>
                  </div>
                  {matrix && <Grid matrix={matrix} size="5x5" />}
                </div>
              </div>
            </div>
          </main>
        </div>

        <div className="relative bg-blue-50">
          <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
              <span className="block">
                <FormattedMessage id="index.try.title" />
              </span>
              <span className="block text-blue-600">
                <FormattedMessage id="index.try.description" />
              </span>
            </h2>
            <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/app/"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                >
                  <FormattedMessage id="index.try.play" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-16 overflow-hidden bg-white xl:py-36 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-max lg:max-w-7xl">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="text-base max-w-prose lg:max-w-none">
                <h2 className="font-semibold leading-6 tracking-wide text-gray-400 uppercase">
                  <FormattedMessage id="index.content.title" />
                </h2>
                <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  <FormattedMessage id="index.content.description" />
                </p>
              </div>
            </div>
            <div className="relative">
              <svg
                className="absolute top-0 right-0 hidden -mt-20 -mr-20 md:block"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
                />
              </svg>
              <svg
                className="absolute bottom-0 left-0 hidden -mb-20 -ml-20 md:block"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
                />
              </svg>
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-lg text-gray-500 prose-blue lg:max-w-none">
                    <FormattedMessage
                      id="index.content.content.left"
                      values={{
                        p: (...chunks) => <p>{chunks}</p>,
                      }}
                    />
                  </div>
                  <div className="mt-6 prose prose-lg text-gray-500 prose-blue lg:mt-0">
                    <FormattedMessage
                      id="index.content.content.right"
                      values={{
                        p: (...chunks) => <p>{chunks}</p>,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="bg-white border-t">
          <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
            <div className="mt-8 md:mt-0 md:order-1">
              <p className="text-base text-center text-gray-400">
                &copy; 2020 Futoshiki. <FormattedMessage id="footer.rights" />
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export const IndexContainer: React.FC<
  ContainerProps & RouteComponentProps
> = props => <IndexWrapper {...props} />

export default connector(IndexContainer)
